<template>
    <div
        class="tw-mb-12 tw-flex md:tw-flex-row tw-flex-col tw-justify-between tw-items-center discountBanner"
        :class="{'tw-cursor-pointer' : clickable}"
        @click="clickable ? trackBannerClicks() : null"
    >
     
        <div class="tw-flex tw-justify-center tw-items-center md:tw-items-start tw-flex-col md:tw-justify-items-start tw-gap-2 tw-w-4/5">
         <div class="tw-flex tw-justify-start">
           <discountBanner class="!tw-w-64 " />
        </div>
            
            <h1 class="tw-flex tw-flex-col md:tw-flex-row md:tw-items-start  tw-items-center tw-leading-[3.5rem] md:tw-text-start tw-text-center ">
                <span class="tw-text-[#ffc628] md:tw-text-4xl tw-text-4xl   md:tw-font-semibold  md:tw-me-1 " :class="{
                    'tw-font-bold': $i18n.locale === 'ar',
                    'tw-font-medium': $i18n.locale === 'en'}">
                 <!--    {{$t('matchedTalents.exclusive')}} -->

                     <span class="tw-text-black md:tw-text-[28px] tw-text-4xl  md:tw-leading-[1.5rem] tw-leading-[3rem] tw-text-center md:tw-text-start " 
               
                >
                  {{$t('matchedTalents.DiscountsWebsite')}}
                </span>
                </span>
              <!--   <span class="tw-text-black md:tw-text-4xl tw-text-4xl  tw-leading-[3.5rem] tw-text-center md:tw-text-start  "
                  :class="{
                    'tw-font-bold': $i18n.locale === 'ar',
                    'tw-font-medium tw-hidden': $i18n.locale === 'en'}"
                :class="{
                    'tw-font-bold  tw-hidden': $i18n.locale === 'ar',
                    'tw-font-medium tw-flex': $i18n.locale === 'en'}"
                >
                  {{$t('matchedTalents.DiscountsWebsite')}}
                </span> -->
            </h1>
        <p class="tw-text-black tw-text-lg tw-font-medium tw-leading-relaxed"> {{$t('matchedTalents.DiscountsWebsiteP')}} </p>

        </div>
        <div class="tw-flex tw-items-center tw-justify-end md:tw-w-1/4">
            <img loading="lazy" src="@/assets/images/matching/discount.svg" alt="discount" class=" tw-w-[230px] tw-object-contain" :class="$i18n.locale == 'en' ? ' tw-flex ' : ' tw-hidden ' " />
            <img loading="lazy" src="@/assets/images/matching/discount-ar.svg" alt="discount" class=" tw-w-[230px] tw-object-contain" :class="$i18n.locale == 'ar' ? ' tw-flex ' : ' tw-hidden ' " />
                    
        </div>

    </div>
</template>

<script>
import discountBanner from "@/pages/Client/clientViewTalentProfile/components/salary/discountBanner.vue";
//import exclusiveDiscount from '@/components/matching/discountBanner/exclusiveDiscount.vue'
export default {
  components: {
      discountBanner
  },
  props: {
    clickable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    trackBannerClicks() {
      if (window.gtag)
        window.gtag("event", "discounts_banner_clicked");

      this.$router.push('/client-signup');
    },
  },
}
</script>

<style lang="scss" scoped>
.discountBanner {
    background-image: url('../../../assets/images/matching/bg_header.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 12px;
    padding: 10px 5rem;

    @media (max-width: 568px) { 
            padding: 30px 2rem;
    }
    
}
</style>